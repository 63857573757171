import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { initializePixel, pageview } from '../util/facebook-pixel'

function FacebookPixel() {
	const location = useLocation()

	useEffect(() => {
		// Initialize pixel on first load
		initializePixel()
	}, [])

	useEffect(() => {
		// Track page views when route changes
		pageview()
	}, [location])

	return null
}

export default FacebookPixel
