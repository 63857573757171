export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID

export const initializePixel = () => {
	if (typeof window !== 'undefined') {
		// Load the Facebook Pixel script
		;(function (f, b, e, v, n, t, s) {
			if (f.fbq) return
			n = f.fbq = function () {
				n.callMethod
					? n.callMethod.apply(n, arguments)
					: n.queue.push(arguments)
			}
			if (!f._fbq) f._fbq = n
			n.push = n
			n.loaded = !0
			n.version = '2.0'
			n.queue = []
			t = b.createElement(e)
			t.async = !0
			t.src = v
			s = b.getElementsByTagName(e)[0]
			s.parentNode.insertBefore(t, s)
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js'
		)

		// Initialize the pixel
		window.fbq('init', FB_PIXEL_ID)
		window.fbq('track', 'PageView')
	}
}
export const pageview = () => {
	if (typeof window !== 'undefined' && window.fbq) {
		window.fbq('track', 'PageView')
	}
}

// export const trackDonationSuccess = (fundName, amount) => {
// 	if (typeof window !== 'undefined' && window.fbq) {
// 		window.fbq('track', 'Donation', {
// 			content_name: fundName,
// 			value: amount
// 		})
// 	}
// }

export const trackDonationSuccess = (fundName, amount) => {
	if (typeof window !== 'undefined' && window.fbq) {
		console.log('fbq is available, sending event')
		window.fbq('track', 'Purchase', {
			content_name: fundName,
			currency: 'BDT',
			value: amount
		})

		// Custom event backup
		window.fbq('trackCustom', 'Donation', {
			content_name: fundName,
			currency: 'BDT',
			value: amount
		})
		console.log('Events sent to Facebook Pixel')
	} else {
		console.log('fbq is not available')
	}
}
